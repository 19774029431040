import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth.gaurd';
import { AuthConfig, OAuthModule, OAuthService, OAuthStorage, UrlHelperService } from 'angular-oauth2-oidc';

@NgModule({
  imports: [CommonModule, OAuthModule.forRoot()],
  providers: [AuthGuard, OAuthService, { provide: OAuthStorage, useValue: localStorage }],
})
export class AuthModule {
  static config(authConfig: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [{ provide: AuthConfig, useValue: authConfig }],
    };
  }
}
