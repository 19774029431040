<div class="feature-panel">
  <div class="row">
    <div *ngFor="let item of features" class="col p-1">
      <mat-card (click)="redirect(item.path)" style="height: 100%">
        <mat-card-header >
          <div mat-card-avatar></div>
          <mat-card-title>{{item.title}}</mat-card-title>
          <mat-card-subtitle>{{item.description}}</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>
  </div>
</div>