import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, filter, from, of } from 'rxjs';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  title = '@mvb-admin/core';
  constructor(private oauthService: OAuthService) {}
}
