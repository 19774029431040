import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent implements OnInit {
  features: {
    title: string;
    description?: string;
    path: string;
  }[] = [
    {
      title: 'MVB CORE',
      description: 'To manage mvb core feature.',
      path: '/core',
    },
    // {
    //   title:'MVB DM',
    //   description:'',
    //   path: ''
    // },
    {
      title: 'System',
      description: 'System and configuration setup.',
      path: '/sys',
    },
    {
      title: 'Board Software',
      description: 'board software management and check status.',
      path: '/mvb-sw',
    },
    {
      title: 'MVB Originals',
      description: 'Content platform for original content.',
      path: 'originals',
    },
  ];
  constructor(
    private router: Router // private oauthService: OAuthService
  ) {}

  ngOnInit(): void {}

  redirect(path: string) {
    // this.router.navigate([path]);
    window.location.href = path;
  }
}
