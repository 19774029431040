import { Component, Inject } from '@angular/core';
import { INavData } from '@coreui/angular';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from '../icon-set';
import { LayoutConfig, LAYOUT_CONFIG } from '../layout-config';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {
  public navItems: INavData[] = [];
  public majorName = '';
  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(@Inject(LAYOUT_CONFIG) config: LayoutConfig, private iconSetService: IconSetService) {
    this.navItems = config.NavConfig;
    this.majorName = config.ProjectName || this.majorName;
    this.iconSetService.icons = { ...iconSubset };
  }
  onClickBrand() {
    window.location.href = '/portal';
  }
}
