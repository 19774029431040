import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule, MaterialOverlayModule, MaterialSnackModule } from '@mvb/mat-ui-lib';

import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PortalComponent } from './portal.component';
@NgModule({
  declarations: [PortalComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    MaterialModule,
    MaterialSnackModule,
    MaterialOverlayModule,
    BrowserAnimationsModule,
  ],
})
export class PortalModule {}
