import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/guard/auth.gaurd';
import { PortalComponent } from '../../../portal/src/app/component/portal/portal.component';
import { DefaultLayoutComponent } from '@shared/containers';
import { EmailTemplateModule } from './component/email/email-template.module';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: PortalComponent,
  },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: PortalComponent,
  },
  {
    path: 'core',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./component/overview/module').then(m => m.OverviewModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./component/user/module').then(m => m.AccountModule),
      },
      {
        path: 'entity',
        loadChildren: () => import('./component/entity/entity.module').then(m => m.EntityModule),
      },
      {
        path: 'subscription',
        loadChildren: () => import('./component/subscription/module').then(m => m.SubscriptionModule),
      },
      {
        path: 'permission',
        loadChildren: () => import('./component/permission/module').then(m => m.PermissionModule),
      },
      {
        path: 'email',
        loadChildren: () => import('./component/email/email-template.module').then(m => m.EmailTemplateModule),
      },
      {
        path: 'locale',
        data: {
          title: 'locale',
        },
        loadChildren: () => import('./component/locale/module').then(m => m.LocaleModule),
      },
      {
        path: 'pd-community',
        data: {
          title: 'pd-community',
        },
        loadChildren: () => import('./component/community/module').then(m => m.CommunityModule),
      },
    ],
  },

  // children: [
  //   {
  //     path: 'dashboard',
  //     loadChildren: () =>
  //       import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
  //   },
  //   {
  //     path: 'theme',
  //     loadChildren: () =>
  //       import('./views/theme/theme.module').then((m) => m.ThemeModule)
  //   },
  //   {
  //     path: 'base',
  //     loadChildren: () =>
  //       import('./views/base/base.module').then((m) => m.BaseModule)
  //   },
  //   {
  //     path: 'buttons',
  //     loadChildren: () =>
  //       import('./views/buttons/buttons.module').then((m) => m.ButtonsModule)
  //   },
  //   {
  //     path: 'forms',
  //     loadChildren: () =>
  //       import('./views/forms/forms.module').then((m) => m.CoreUIFormsModule)
  //   },
  //   {
  //     path: 'charts',
  //     loadChildren: () =>
  //       import('./views/charts/charts.module').then((m) => m.ChartsModule)
  //   },
  //   {
  //     path: 'icons',
  //     loadChildren: () =>
  //       import('./views/icons/icons.module').then((m) => m.IconsModule)
  //   },
  //   {
  //     path: 'notifications',
  //     loadChildren: () =>
  //       import('./views/notifications/notifications.module').then((m) => m.NotificationsModule)
  //   },
  //   {
  //     path: 'widgets',
  //     loadChildren: () =>
  //       import('./views/widgets/widgets.module').then((m) => m.WidgetsModule)
  //   },
  //   {
  //     path: 'pages',
  //     loadChildren: () =>
  //       import('./views/pages/pages.module').then((m) => m.PagesModule)
  //   },
  // ]
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
