// layout set up
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent } from './index';
import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  INavData,
  ListGroupModule,
  NavModule,
  ProgressModule,
  SharedModule,
  SidebarModule,
  TabsModule,
  UtilitiesModule,
} from '@coreui/angular';

import { IconModule, IconSetService } from '@coreui/icons-angular';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutConfig, LAYOUT_CONFIG } from './layout-config';
import { MaterialModule } from '@mvb/mat-ui-lib';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent];
@NgModule({
  declarations: [...APP_CONTAINERS],
  imports: [
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    FooterModule,
    FormModule,
    GridModule,
    HeaderModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    SharedModule,
    SidebarModule,
    TabsModule,
    UtilitiesModule,
    PerfectScrollbarModule,
    IconModule,
    RouterModule,
    MaterialModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    // [{
    //   provide: LAYOUT_CONFIG, useValue: {
    //     NavConfig: []
    //   }
    // }],
    IconSetService,
  ],
  exports: [DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent],
  // exports: [
  //   AvatarModule,
  //   BadgeModule,
  //   BreadcrumbModule,
  //   ButtonGroupModule,
  //   ButtonModule,
  //   CardModule,
  //   DropdownModule,
  //   FooterModule,
  //   FormModule,
  //   GridModule,
  //   HeaderModule,
  //   ListGroupModule,
  //   NavModule,
  //   ProgressModule,
  //   SharedModule,
  //   SidebarModule,
  //   TabsModule,
  //   UtilitiesModule,
  //   PerfectScrollbarModule
  // ]
})
export class CoreUIModule {
  static getProviders() {
    return [
      // {
      //   provide: PERFECT_SCROLLBAR_CONFIG,
      //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // }
    ];
  }
  static forRoot(config: LayoutConfig): ModuleWithProviders<CoreUIModule> {
    return {
      ngModule: CoreUIModule,
      providers: [
        {
          provide: LAYOUT_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
