import { BehaviorSubject, from, map, Observable, tap, catchError, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  CanActivateChild,
  ActivatedRoute,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { OIDCAuthGuard } from './oidc.gaurd';

@Injectable()
export class AuthGuard extends OIDCAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private activatedRoute: ActivatedRoute,
    public override router: Router,
    public OAuthService: OAuthService
  ) {
    super(router, OAuthService);
  }

  override canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkAuth();
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkAuth();
  }

  checkAuth() {
    return from(this.getUserDataByCodeOrToken()).pipe(
      catchError((error: HttpErrorResponse) => {
        return from(this.refetchTokenAndUserProfile());
      }),
      map(profile => {
        return profile ? true : false;
      })
    );
  }
}
