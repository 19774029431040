import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreUIModule } from '@shared/containers/core-ui.module';
import { navItems } from './_nav';
import { MaterialModule, MaterialOverlayModule, MaterialSnackModule, SnackbarService } from '@mvb/mat-ui-lib';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@core/env/environment';
import { OIDCHttpInterceptor, OIDCHttpWithTransformInterceptor } from '@shared/guard/http-interceptor';
import { PortalModule } from '../../../portal/src/app/component/portal/module';
import { AuthModule } from '@shared/guard/auth.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    CoreUIModule.forRoot({
      ProjectName: 'MVB-CORE',
      NavConfig: navItems,
    }),
    AuthModule.config(environment.oidcConfig),
    MaterialSnackModule,
    MaterialOverlayModule,
    BrowserAnimationsModule,
    PortalModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OIDCHttpWithTransformInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

@NgModule({})
export class AppSharedModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: [],
    };
  }
}
