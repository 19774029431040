import { AuthConfig } from 'angular-oauth2-oidc';

const oidcConfig: AuthConfig = {
  issuer: 'https://api.dev.myviewboard.com/oidc/provider',
  redirectUri: window.location.origin + '/portal',
  clientId: 'admin-core-client',
  dummyClientSecret: 'r7Kg)hg5x/zT4cQb',
  responseType: 'code',
  scope: 'openid email',
  nonceStateSeparator: ';',
  showDebugInformation: true,
  timeoutFactor: 0.8,
};
export const environment = {
  envName: 'dev',
  apiPath: 'https://api.dev.myviewboard.com',
  apiAuthOidcUrl: 'https://auth.dev.myviewboard.com/',
  emailBuilderPath: 'https://admin.dev.myviewboard.com/email_builder/',
  oidcConfig,
};
