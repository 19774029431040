import { INavData } from '@coreui/angular';

const account_module = {
  name: 'User',
  url: '/account',
  iconComponent: { name: 'cil-calculator' },
  children: [
    {
      name: 'List',
      url: '/account/list',
    },
    {
      name: 'Configuration',
      url: '/account/config',
    },
  ],
};
const entity_module = {
  name: 'Entity',
  url: '/entity',
  iconComponent: { name: 'cil-notes' },
  children: [
    {
      name: 'List',
      url: '/entity/realms',
    },
    {
      name: 'Signup Request',
      url: '/entity/register-requests',
    },
    {
      name: 'Configuration',
      url: '/entity/config',
    },
    {
      name: 'Subscription Request',
      url: '/entity/onboard-subscription',
    },
    {
      name: 'Manage Service Provider',
      url: '/entity/msp',
    },
  ],
};
const subscription = {
  name: 'Subscription',
  url: '/subscription',
  iconComponent: { name: 'cil-star' },
  children: [
    {
      name: 'Service Products',
      url: '/subscription/service-instance',
    },
    {
      name: 'Transaction History',
      url: '/subscription/history/entity',
    },
  ],
};
const permission_module = {
  name: 'Permission',
  url: '/permission',
  iconComponent: { name: 'cil-user' },
  children: [
    {
      name: 'Base Definition',
      url: '/permission/basic-definition',
    },
    {
      name: 'Permission Group',
      url: '/permission/group',
    },
    {
      name: 'Scope(Project) Setup',
      url: '/permission/scope',
    },
    {
      name: 'Attribute Key Definition',
      url: '/permission/key-attribute',
    },
  ],
};

const email_template_module = {
  name: 'Email',
  url: '/email',
  iconComponent: { name: 'cil-envelope-closed' },
  children: [
    {
      name: 'Template',
      url: '/email/template-setup',
    },
    {
      name: 'Contributor',
      url: '/email/language',
    },
    {
      name: 'Log',
      url: '/email/history',
    },
  ],
};

const locale_module = {
  name: 'Locale setup',
  url: '/locale',
  iconComponent: { name: 'cil-notes' },
  children: [
    {
      name: 'Language setting',
      url: '/locale/language',
    },
  ],
};

const pd_module = {
  name: 'PD & community',
  url: '/pd-community',
  iconComponent: { name: 'cil-people' },
};

const overview_module = {
  name: 'Overview',
  url: '/',
  iconComponent: { name: 'cil-chart' },
};
const addPrefix = (items: { name: string; url: string }[], prefix = '/core') => {
  const _items: INavData[] = items.map((item: any) => {
    item.url = prefix + item.url;
    if (item.children && item.children?.length > 0) {
      addPrefix(item.children, prefix);
    }
    return item;
  });
  return _items;
};
const items = [
  overview_module,
  account_module,
  entity_module,
  subscription,
  permission_module,
  email_template_module,
  locale_module,
  pd_module,
];
export const navItems: INavData[] = addPrefix(items, '/core');
