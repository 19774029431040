import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {
  @Input() sidebarId = 'sidebar';

  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);
  public identity: any;
  constructor(private classToggler: ClassToggleService, private authService: OAuthService) {
    super();
    this.identity = this.authService.getIdentityClaims();
  }
  logout() {
    this.authService.logOut();
  }
}
