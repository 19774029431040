import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpContext,
  HttpHeaders,
  HttpParams,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService, SnackbarService } from '@mvb/mat-ui-lib';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, finalize, map, Observable, of } from 'rxjs';

@Injectable()
export class OIDCHttpInterceptor implements HttpInterceptor {
  constructor(private msgService: SnackbarService, private oauthService: OAuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isIDP = !!request.url.match(/https:\/\/auth(.|.dev.|.stage.)myviewboard.com|\/oidc\/provider/);
    const params: { [key: string]: any } = {};
    const bearer = this.oauthService.authorizationHeader();
    const token = this.oauthService.getAccessToken();
    if (token && bearer && !isIDP) {
      request = request.clone({ headers: request.headers.set('Authorization', bearer) });
    }
    request = request.clone(params);
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let message = err.error;
        try {
          const errorInstance = typeof message === 'string' ? JSON.parse(err.error) : err.error;
          message = errorInstance.message;
        } catch (e) {
          console.log(e);
        }
        this.msgService.openError(message);
        throw err;
      })
    );
  }
  transformBody(body: any): any | null {
    return null;
  }
}

@Injectable()
export class OIDCHttpWithTransformInterceptor extends OIDCHttpInterceptor {
  private concurrentRequest = 0;
  constructor(private loadingService: LoadingService, msgService: SnackbarService, oauthService: OAuthService) {
    super(msgService, oauthService);
  }
  startLoading() {
    if (this.concurrentRequest == 0) {
      this.loadingService.spin$.next(true);
    }
    this.concurrentRequest++;
  }
  checkEndOfLoading() {
    if (this.concurrentRequest > 0) {
      this.concurrentRequest--;
    }
    if (this.concurrentRequest == 0) {
      this.loadingService.spin$.next(false);
    }
  }

  override intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.startLoading();
    return super.intercept(request, next).pipe(
      finalize(this.checkEndOfLoading.bind(this)),
      map((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          // convert Object data to array
          const transformBody = this.transformBody(evt.body);
          if (transformBody) {
            return evt.clone({
              body: transformBody,
            });
          }
        }
        return evt;
      }),
      catchError((err: HttpErrorResponse) => {
        throw err;
      })
    );
  }

  override transformBody(body: any) {
    if (body && body.list && body.total) {
      return {
        list: body.list,
        rows: body.list,
        count: body.count,
        page: body.page,
      };
    }
    return null;
  }
}
