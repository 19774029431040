import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export abstract class OIDCAuthGuard implements CanActivate {
  constructor(public router: Router, public oauthService: OAuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw new Error('Method not implemented.');
  }
  async getUserDataByCodeOrToken() {
    await this.configureCodeFlow();
    return await this.oauthService.loadUserProfile();
  }
  async refetchTokenAndUserProfile() {
    const retryTag = sessionStorage.getItem('sso-retry');
    if (retryTag === null) sessionStorage.setItem('sso-retry', '0');
    let retry_times = Number(sessionStorage.getItem('sso-retry') || '0');
    while (retry_times <= 1) {
      try {
        await this.oauthService.refreshToken();
        return await this.oauthService.loadUserProfile();
      } catch (error) {
      } finally {
        retry_times++;
        sessionStorage.setItem('sso-retry', `${retry_times}`);
      }
    }
    sessionStorage.removeItem('sso-retry');
    this.oauthService.logOut(true);
    await this.oauthService.initCodeFlow();
    return null;
  }
  async configureCodeFlow() {
    await this.oauthService.loadDiscoveryDocument();
    await this.oauthService.tryLogin();

    // Optional
    await this.oauthService.setupAutomaticSilentRefresh();
  }
}
