import { InjectionToken } from '@angular/core';

import { INavData } from '@coreui/angular';

export interface LayoutConfig {
  ProjectName?: string;
  NavConfig: INavData[];
}
// export const NAV_CONFIG = new InjectionToken<LayoutConfig>('NAV item', {
//   providedIn: 'root',
//   factory: () => ({
//     NavConfig: navItems
//   })
// });
export const LAYOUT_CONFIG = new InjectionToken<LayoutConfig>('layout.config');
